<template>
  <div class="market" data-view>
    <div class="market__hero hero">
      <div class="container">
        <h1 class="hero__title title" v-animate-onscroll="'animated backIn'">
          Маркетплейс
        </h1>
        <div class="hero__text" v-animate-onscroll="'animated backIn delay-05'">
          <p class="p-3">
            Работа через EdiFin даёт возможность не&nbsp;зависеть
            от&nbsp;жёстких требований банков и&nbsp;крупных факторов. Наши
            партнёры предлагают индивидуальный подход. Выбирайте лучшие условия
            и&nbsp;отправляйте запрос, получая в&nbsp;ответ выгодные
            предложения.
          </p>
        </div>
      </div>
      <div class="hero__graph">
        <v-graph
          :scheme="{
            scatter: 0.25,
            angle: 20,
            offset: 0.0,
            background: {
              start: 'rgba(206, 229, 139, 0.16)',
              stop: 'rgba(206, 229, 139, 0)',
            },
            points: {
              count: 8,
              background: 'rgba(235, 248, 223, 0)',
              border: {
                width: 4,
                color: 'rgba(179, 221, 136, 0)',
              },
              radius: 6,
            },
            line: {
              width: 2,
              color: 'rgba(179, 221, 136, 0)',
            },
            animation: {
              duration: 500,
              delay: 2000,
            },
          }"
        />
        <v-graph
          :scheme="{
            scatter: 0.25,
            angle: 15,
            offset: 0.0,
            background: {
              start: 'rgba(118, 200, 120, 0.16)',
              stop: 'rgba(118, 200, 120, 0)',
            },
            points: {
              count: 8,
              background: 'rgba(235, 248, 223, 1)',
              border: {
                width: 4,
                color: 'rgba(179, 221, 136, 1)',
              },
              radius: 6,
            },
            line: {
              width: 2,
              color: 'rgba(179, 221, 136, 1)',
            },
            animation: {
              duration: 500,
              delay: 2000,
            },
          }"
        />
        <v-graph
          :scheme="{
            scatter: 0.25,
            angle: 10,
            offset: 0.0,
            background: {
              start: 'rgba(21, 164, 161, 0.16)',
              stop: 'rgba(21, 164, 161, 0)',
            },
            points: {
              count: 7,
              background: 'rgba(214, 244, 237, 0)',
              border: {
                width: 4,
                color: 'rgba(174, 231, 219, 0)',
              },
              radius: 6,
            },
            line: {
              width: 2,
              color: 'rgba(174, 231, 219, 0)',
            },
            animation: {
              duration: 500,
              delay: 2000,
            },
          }"
        />
      </div>
    </div>
    <div class="market__cards">
      <div class="container">
        <el-row type="flex" class="market__card">
          <el-col :span="10" :xs="24" v-animate-onscroll="'animated backIn'">
            <v-card
              class="card--3"
              @click.native="$modal.show('factoring-regressed')"
              :imgSrc="require('@/assets/img/cards/03.svg')"
            >
              <template v-slot:title>
                Факторинг <br />
                c&nbsp;регрессом
              </template>
              <template v-slot:text>
                Финансирование до 100% <br />
                До 180 дней <br />
                Любые дебиторы
              </template>
            </v-card>
          </el-col>
          <el-col :span="12" :xs="24" v-animate-onscroll="'animated backIn'">
            <h3 class="market__card-title">Увеличивайте объёмы поставок</h3>
            <p class="p-4 market__card-text">
              Сохраните свои цепи поставок в&nbsp;целостности. Вы&nbsp;можете
              получить оплату за&nbsp;поставку в&nbsp;день приёмки, увеличить
              товарооборот, а&nbsp;дебитор сохранит отсрочку.
            </p>
            <el-button
              plain
              type="success"
              @click="$modal.show('factoring-regressed')"
              >Отправить запрос</el-button
            >
          </el-col>
        </el-row>
        <el-row type="flex" class="market__card">
          <el-col :span="10" :xs="24" v-animate-onscroll="'animated backIn'">
            <v-card
              class="card--2"
              @click.native="$modal.show('factoring-closed')"
              :imgSrc="require('@/assets/img/cards/02.svg')"
            >
              <template v-slot:title>
                Закрытый <br />
                факторинг
              </template>
              <template v-slot:text>
                Финансирование до 90% <br />
                До 120 дней <br />
                Номинальный счёт
              </template>
            </v-card>
          </el-col>
          <el-col :span="12" :xs="24" v-animate-onscroll="'animated backIn'">
            <h3 class="market__card-title">Не нужно информировать дебитора</h3>
            <p class="p-4 market__card-text">
              Факторинговая сделка состоится даже, если покупатель
              не&nbsp;согласен на&nbsp;переуступку задолженности
              и&nbsp;не&nbsp;хочет подписывать уведомление. Дебитор
              не&nbsp;присутствует при&nbsp;вашем взаимодействии
              с&nbsp;фактором.
            </p>
            <el-button
              plain
              type="success"
              @click="$modal.show('factoring-closed')"
              >Отправить запрос</el-button
            >
          </el-col>
        </el-row>
        <el-row type="flex" class="market__card">
          <el-col :span="10" :xs="24" v-animate-onscroll="'animated backIn'">
            <v-card
              :imgSrc="require('@/assets/img/cards/01.svg')"
              class="card--1"
              @click.native="$modal.show('factoring-not-regressed')"
            >
              <template v-slot:title> Факторинг <br />без регресса </template>
              <template v-slot:text>
                Покупка до 100% <br />
                До 120 дней <br />&nbsp;
              </template>
            </v-card>
          </el-col>
          <el-col :span="12" :xs="24" v-animate-onscroll="'animated backIn'">
            <h3 class="market__card-title">Передайте все заботы фактору</h3>
            <p class="p-4 market__card-text">
              Фактор возьмёт на&nbsp;себя риски невыплаты со&nbsp;стороны
              дебитора, будет управлять просроченной дебиторской задолженностью
              и&nbsp;самостоятельно осуществлять действия, связанные
              с&nbsp;её&nbsp;взысканием.
            </p>
            <el-button
              plain
              type="success"
              @click="$modal.show('factoring-not-regressed')"
              >Отправить запрос</el-button
            >
          </el-col>
        </el-row>
        <el-row type="flex" class="market__card">
          <el-col :span="10" :xs="24" v-animate-onscroll="'animated backIn'">
            <v-card
              class="card--4"
              @click.native="$modal.show('factoring-reveresed')"
              :imgSrc="require('@/assets/img/cards/04.svg')"
            >
              <template v-slot:title>
                Реверсивный <br />
                факторинг
              </template>
              <template v-slot:text>
                Финансирование до 100% <br />
                До 120 дней <br />
                Любые поставщики
              </template>
            </v-card>
          </el-col>
          <el-col :span="12" :xs="24" v-animate-onscroll="'animated backIn'">
            <h3 class="market__card-title">
              Идеально для быстрорастущих компаний
            </h3>
            <p class="p-4 market__card-text">
              Подходит вашей компании при&nbsp;наличии проверенных поставщиков.
              Услуга обеспечит беспрерывное финансирование оборотных средств
              пропорционально росту продаж.
            </p>
            <el-button
              plain
              type="success"
              @click.native="$modal.show('factoring-reveresed')"
              >Отправить запрос</el-button
            >
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="market__calc">
      <div class="container">
        <h2 class="market__calc-title" v-animate-onscroll="'animated backIn'">
          Факторинговый калькулятор
        </h2>
        <p
          class="market__calc-subtitle p-3"
          v-animate-onscroll="'animated backIn delay-05'"
        >
          Рассчитайте возможную стоимость финансирования
        </p>
        <v-calc />
      </div>
    </div>
    <div class="market__form">
      <v-form-block />
    </div>

    <v-dialog name="factoring-not-regressed">
      <h2 class="b24">Отправить запрос</h2>
      <p class="b24">
        Мы свяжемся с вами, и найдем лучшие ставки и условия среди факторов,
        подключенных к EdiFin
      </p>
      <v-form :action="'https://ediweb.com/subscribe/742ecb4f'" />
    </v-dialog>

    <v-dialog name="factoring-closed">
      <h2 class="b24">Отправить запрос</h2>
      <p class="b24">
        Мы свяжемся с вами, и найдем лучшие ставки и условия среди факторов,
        подключенных к EdiFin
      </p>
      <v-form :action="'https://ediweb.com/subscribe/ea58d804'" />
    </v-dialog>

    <v-dialog name="factoring-regressed">
      <h2 class="b24">Отправить запрос</h2>
      <p class="b24">
        Мы свяжемся с вами, и найдем лучшие ставки и условия среди факторов,
        подключенных к EdiFin
      </p>
      <v-form :action="'https://ediweb.com/subscribe/2ab23d80'" />
    </v-dialog>

    <v-dialog name="factoring-reveresed">
      <h2 class="b24">Отправить запрос</h2>
      <p class="b24">
        Мы свяжемся с вами, и найдем лучшие ставки и условия среди факторов,
        подключенных к EdiFin
      </p>
      <v-form :action="'https://ediweb.com/subscribe/5a4da2b0'" />
    </v-dialog>
  </div>
</template>

<script>
const meta = {
  title: "Маркетплейс",
  description: "Поможем получить финансирова­ние ваших неоплачен­ных счетов",
};

export default {
  components: {
    "v-card": () => import("@/components/Card"),
    "v-form-block": () => import("@/components/FormBlock"),
    "v-dialog": () => import("@/components/Dialog"),
    "v-form": () => import("@/components/Form"),
    "v-calc": () => import("@/components/Calc"),
    "v-graph": () => import("@/components/Graph"),
  },
  metaInfo: {
    title: meta.title,
    meta: [
      {
        name: "og:title",
        content: meta.title,
      },
      {
        name: "description",
        content: meta.description,
      },
      {
        name: "og:description",
        content: meta.description,
      },
    ],
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";

.market {
  &__cards {
    padding: $space-80 0 $space-160;

    @include tablet {
      padding: $space-64 0;
    }

    @include mobile {
      padding: $space-56 0;
    }
  }

  &__card {
    padding: $space-120 0;
    border-bottom: 1px solid $gray-50;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include tablet {
      padding: $space-64 0;
    }

    @include mobile {
      padding: $space-40 0;
      flex-direction: column;
      justify-content: flex-start;

      button {
        width: 100%;
        font-size: 16px;
        padding: $space-8 0;
      }

      .card {
        margin-bottom: $space-24;
      }
    }

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
      border-bottom: none;
    }

    &-title {
      margin: 0;

      @include tablet {
        font-size: 28px;
      }

      @include mobile {
        font-size: 24px;
      }
    }

    &-text {
      margin: $space-24 0 $space-32;

      @include tablet {
        font-size: 16px;
      }
    }
  }

  &__calc {
    padding: $space-80 0;
    background-color: $gray-light;

    @include mobile {
      padding: $space-48 0;
    }

    &-title {
      margin-bottom: $space-32;
      text-align: center;

      @include mobile {
        margin-bottom: $space-24;
        text-align: left;
      }
    }

    &-subtitle {
      margin-bottom: $space-56;
      text-align: center;

      @include mobile {
        margin-bottom: $space-32;
        text-align: left;
      }
    }
  }
}
</style>
